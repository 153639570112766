import { TOKEN_TYPE } from "../TokenType.js";
import Token from "../Token.js";

export default class ConstantToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.CONSTANT, value );
    }

    getOperandType() {
        if ( this.token != null ) {
            return this.token.getType();
        }
        return TOKEN_TYPE.UNDEFINED;
    }

    getValue() {
        if ( this.token != null ) {
            return this.token.getValue();
        }
        return null;
    }

    getName() {
        return this.value;
    }

    getOperatorType() {
        return this.value.toString();
    }

    toString() {
        return this.token.getValue();
    }
}
