import Config from "../Config.js";
import { TOKEN_TYPE } from "./../Tokenizer/TokenType.js";
import { Operations } from "./../Operations/Operations.js";
import VariablesManager from "../Variables/VariablesManager.js";
import ErrorManager from "../ErrorManager.js";
import TokenFactory from "../Tokenizer/TokenFactory.js";

export default class Solver {
    constructor() {
    }

    solve( tokens ) {
        const newTokens = [];
        let newVars = [];
        for ( let i = 0; i < tokens.length; ++i ) {
            const token = tokens[ i ];
            if ( token.getType() == TOKEN_TYPE.OPERATOR ) {
                if ( newTokens.length >= token.getValue().arguments ) {
                    const args = [];
                    for ( let i = 0; i < token.getValue().arguments; ++i ) {
                        args.push( newTokens.pop() );
                    }
                    const opResult = this.compute( token, args );
                    if ( opResult != null ) {
                        if ( opResult.isError() ) {
                            return {
                                result: [],
                                error: true,
                                errorInfo: opResult.getValue(),
                                errorArgs: token.value.name
                            };
                        }
                        newTokens.push( opResult );
                    }
                } else {
                    return {
                        result: [],
                        error: true,
                        errorInfo: ErrorManager.Error.NOT_ENOUGH_OPERANDS_FOR_OPERATOR,
                        errorArgs: token.getValue().operator
                    };
                }
            } else if ( token.getType() == TOKEN_TYPE.FUNCTION ) {
                let end = false;
                const args = [];
                while ( newTokens.length > 0 && !end ) {
                    const currentToken = newTokens.pop();
                    if ( currentToken.getType() == TOKEN_TYPE.LEFT_PARENTHESIS ) {
                        end = true;
                    } else if ( currentToken.getType() != TOKEN_TYPE.COMMA ) {
                        args.push( currentToken );
                    }
                }
                if ( end ) {
                    const numberOfOperands = token.getValue().arguments;
                    if ( numberOfOperands != -1 ) {
                        if ( numberOfOperands != args.length ) {
                            return {
                                result: [],
                                error: true,
                                errorInfo: numberOfOperands < args.length ? ErrorManager.Error.TOO_MANY_OPERANDS_FOR_FUNCTION : ErrorManager.Error.NOT_ENOUGH_OPERANDS_FOR_FUNCTION,
                                errorArgs: token.getValue().keyword
                            };
                        }
                    }
                    const opResult = this.compute( token, args );
                    if ( opResult != null ) {
                        newTokens.push( opResult );
                    }
                } else {
                    return {
                        result: [],
                        error: true,
                        errorInfo: ErrorManager.Error.LEFT_PARENTHESIS_NOT_FOUND_FOR_FUNCTION,
                        errorArgs: token.getValue().keyword
                    };
                }
            } else if ( token.getType() == TOKEN_TYPE.VARIABLE ) {
                VariablesManager.updateVariable( token );
                newTokens.push( token );
                newVars.push( token );
            } else if ( token.getType() == TOKEN_TYPE.CONSTANT ) {
                newTokens.push( token.getToken() );
            } else if ( token.getType() == TOKEN_TYPE.COMMA ) {
                this.executePostActions( newVars );
                newVars = [];
            } else if ( token.getType() == TOKEN_TYPE.RIGHT_VECTOR ) {
                const vectorToken = TokenFactory.createVectorToken();
                let vectorTokenChild = null;
                while ( newTokens.length > 0 && ( vectorTokenChild = newTokens.pop() ).getType() != TOKEN_TYPE.LEFT_VECTOR ) {
                    vectorToken.addToken( vectorTokenChild );
                };
                if ( vectorTokenChild.getType() == TOKEN_TYPE.LEFT_VECTOR ) {
                    if ( !this.checkVectorToken( vectorToken ) ) {
                        return {
                            result: [],
                            error: true,
                            errorInfo: ErrorManager.Error.VECTOR_COMPONENTS_DONT_MATCH,
                            errorArgs: "",
                        };
                    }
                    newTokens.push( vectorToken );
                } else {
                    return {
                        result: [],
                        error: true,
                        errorInfo: ErrorManager.Error.NO_END_DELIMITER_FOR_VECTOR,
                        errorArgs: "",
                    };
                }
            } else if ( token.getType() == TOKEN_TYPE.RIGHT_TUPLE ) {
                const tupleToken = TokenFactory.createTupleToken();;
                let tupleTokenChild = null;
                while ( newTokens.length > 0 && ( tupleTokenChild = newTokens.pop() ).getType() != TOKEN_TYPE.LEFT_TUPLE ) {
                    tupleToken.addToken( tupleTokenChild );
                };
                if ( tupleTokenChild.getType() == TOKEN_TYPE.LEFT_TUPLE ) {
                    newTokens.push( tupleToken );
                } else {
                    return {
                        result: [],
                        error: true,
                        errorInfo: ErrorManager.Error.NO_END_DELIMITER_FOR_TUPLE,
                        errorArgs: "",
                    };
                }
            } else {
                newTokens.push( token );
            }
        }
        const error = newTokens.length == 0;
        //
        return {
            result: error ? [] : newTokens,
            error: error,
        };
    }

    compute( token, args ) {
        if ( token.getType() == TOKEN_TYPE.OPERATOR ) {
            const operationId = Config.getOperationId( token.value.name );
            const op = Operations[ operationId ];
            if ( op != null ) {
                this.updateVariables( args );
                return op( args );
            }
            // error function not found
        } else if ( token.getType() == TOKEN_TYPE.FUNCTION ) {
            return token.getValue().function( args );
        }
    }

    updateVariables( args ) {
        args.forEach( token => {
            if ( token.getType() == TOKEN_TYPE.VARIABLE ) {
                VariablesManager.updateVariable( token );
            }
        });
    }

    executePostActions( tokens ) {
        tokens.forEach( token => {
            token.executePostActions();
        });
    }

    checkVectorToken( token ) {
        let tokenType = null;
        for ( let i = 0; i < token.getNumberOfTokens(); i++ ) {
            const childToken = token.getToken( i );
            const currentType = childToken.getType();
            if ( tokenType == null ) {
                tokenType = currentType;
            } else {
                if ( tokenType != currentType ) {
                    if ( currentType == TOKEN_TYPE.DECIMAL ) {
                        if ( tokenType != TOKEN_TYPE.NUMBER ) {
                            return false;
                        }
                    } else if ( currentType == TOKEN_TYPE.NUMBER ) {
                        if ( tokenType != TOKEN_TYPE.DECIMAL ) {
                            return false;
                        }
                    } else {
                        return false;
                    }
                }
            }
        }
        return true;
    }
}