import { TOKEN_TYPE  } from "../TokenType.js";
import Token from "../Token.js";

export default class ErrorToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.ERROR, value );
    }

    setErrorArgs( errorArgs ) {
        this.errorArgs = errorArgs;
    }

    isError() {
        return true;
    }
}