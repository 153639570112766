export default class Config {
    static create( configuration ) {
        Config.self = new Config();
        Config.self.settings = configuration.settings;
        Config.self.operators = configuration.operators;
        Config.self.operations = configuration.operations;
        Config.self.constants = configuration.constants;
        Config.self.functions = configuration.functions;
    }

    static self = null;

    static getOperators() {
        return Config.self.operators;
    }

    static getConstants() {
        return Config.self.constants;
    }

    static getFunctions() {
        return Config.self.functions;
    }

    static getDecimalSeparator() {
        return Config.self.settings.chars.DECIMAL_SEPARATOR.charAt( 0 );
    }

    static getNamespaceSeparator() {
        return Config.self.settings.chars.NAMESPACE_SEPARATOR.charAt( 0 );
    }

    static getLeftParenthesis() {
        return Config.self.settings.chars.LEFT_PARENTHESIS.charAt( 0 );
    }

    static getRightParenthesis() {
        return Config.self.settings.chars.RIGHT_PARENTHESIS.charAt( 0 );
    }

    static getComma() {
        return Config.self.settings.chars.COMMA.charAt( 0 );
    }

    static getGlobalPrefix() {
        return Config.self.settings.vars.global_prefix;
    }

    static getOperationId( operationName ) {
        return Config.self.operations[ operationName ];
    }

    static getStringDelimiter() {
        return Config.self.settings.chars.STRING_DELIMITER.charAt( 0 );
    }

    static getStartVectorDelimiter() {
        return Config.self.settings.chars.START_VECTOR_DELIMITER.charAt( 0 );
    }

    static getEndVectorDelimiter() {
        return Config.self.settings.chars.END_VECTOR_DELIMITER.charAt( 0 );
    }

}
