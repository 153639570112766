import { TOKEN_TYPE } from "../TokenType.js";
import Token from "../Token.js";

export default class TupleToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.TUPLE, value );
        this.token = [];
    }

    addToken( token ) {
        this.token.push( token );
    }

    getValue() {
        return this.toString();
    }

    toString() {
        let result = "[[";
        let first = true;
        this.token.slice().reverse().forEach( token => {
            if ( !first ) {
                result += ", ";
            }
            result += token.toString();
            first = false;
        });
        result += "]]";
        return result;
    }

    getNumberOfTokens() {
        return this.token.length;
    }

    getToken( index ) {
        return this.token[ index ];
    }
}
