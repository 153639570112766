import { TOKEN_TYPE } from "../TokenType.js";
import Token from "../Token.js";

export default class LeftParenthesisToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.LEFT_PARENTHESIS, value );
    }

    toString() {
        return this.value;
    }
}
