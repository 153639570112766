export default class Token {
    constructor( type, value ) {
        this.type = type;
        this.value = value;
        this.token = null;
        this.postActions = [];
    }

    clone() {
        const token = new Token( this.type, this.value );
        token.token = this.token;
        this.postActions.forEach( action => {
            token.postActions.push( action );
        });
        return token;
    }

    assign( token ) {
        // Error: this token can not be assigned. It is not a variable
    }

    assignValue( value ) {
        // Error: this token can not be assigned. It is not a variable
    }

    getType() {
        return this.type;
    }

    getToken() {
        return this.token;
    }

    setToken( token ) {
        this.token = token;
    }

    getOperandType() {
        return this.getType();
    }

    getValue() {
        return this.value;
    }

    getName() {
        return "";
    }

    getOperatorType() {
        return "";
    }

    toString() {
        return "";
    }

    getPrecedence() {
        return 0;
    }

    addPostAction( action ) {
    }

    executePostActions() {
    }

    isError() {
        return false;
    }

    belongsTo( logicalType ) {
        return logicalType.includes( this.getType() );
    }

};
