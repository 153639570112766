export const Operators = [
    {
        "operator": "+",
        "type": "normal",
        "precedence": 100,
        "name": "addition",
        "arguments": 2
    },
    {
        "operator": "-",
        "type": "normal",
        "precedence": 100,
        "name": "substraction",
        "arguments": 2
    },
    {
        "operator": "*",
        "type": "normal",
        "precedence": 200,
        "name": "multiplication",
        "arguments": 2
    },
    {
        "operator": "/",
        "type": "normal",
        "precedence": 200,
        "name": "division",
        "arguments": 2
    },
    {
        "operator": "=",
        "type": "normal",
        "precedence": 50,
        "name": "assign",
        "arguments": 2
    },
    {
        "operator": "-",
        "type": "unitary",
        "precedence": 400,
        "name": "negate",
        "arguments": 1
    },
    {
        "operator": "--",
        "type": "prefix",
        "precedence": 500,
        "name": "predecrement",
        "arguments": 1
    },
    {
        "operator": "--",
        "type": "postfix",
        "precedence": 600,
        "name": "postdecrement",
        "arguments": 1
    }
];
