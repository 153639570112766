import { LOGICAL_TOKEN_TYPE } from "./Tokenizer/LogicalTokenType.js";
import { TOKEN_TYPE } from "./Tokenizer/TokenType.js";

export default class ErrorManager
{
    constructor() {
        this.error = false;
        this.errorInfo = null;
        this.errorArgs = null;
    }

    static Error = {
        TOKEN_NOT_FOUND: {
            Code: 100,
            Description: "Unknown char found",
        },
        LEFT_PARENTHESIS_NOT_FOUND_FOR_FUNCTION: {
            Code: 101,
            Description: "Left parenthesis not found for function",
        },
        MISMATCH_PARENTHESIS: {
            Code: 102,
            Description: "Mismatch parenthesis number",
        },
        NOT_ENOUGH_OPERANDS_FOR_OPERATOR: {
            Code: 200,
            Description: "Not enough operands for operator",
        },
        NOT_ENOUGH_OPERANDS_FOR_FUNCTION: {
            Code: 201,
            Description: "Not enough operands for funcion",
        },
        TOO_MANY_OPERANDS_FOR_FUNCTION: {
            Code: 202,
            Description: "Too many operands for function",
        },
        OPERATOR_ARGUMENTS_TYPE_NOT_VALID: {
            Code: 300,
            Description: "Arguments types for operator are not valid",
        },
        ASSIGMENT_OPERATOR_NEEDS_VARIABLE: {
            Code: 400,
            Description: "a variable type is needed for assigment operator",
        },
        NO_END_DELIMITER_FOR_STRING:
        {
            Code: 500,
            Description: "No end delimiter for string has been found",
        },
        NO_END_DELIMITER_FOR_VECTOR:
        {
            Code: 501,
            Description: "No end delimiter for vector has been found",
        },
        NO_END_DELIMITER_FOR_TUPLE:
        {
            Code: 502,
            Description: "No end delimiter for tuple has been found",
        },
        VECTOR_COMPONENTS_DONT_MATCH:
        {
            Code: 600,
            Description: "Vector components must be of the same type",
        }
    }

    reset() {
        this.error = false;
        this.errorInfo = null;
        this.errorArgs = null;
    }

    isError() {
        return this.error;
    }

    getErrorInfo() {
        return this.errorInfo;
    }

    getErrorArgs() {
        return this.errorArgs;
    }

    setError( errorInfo ) {
        this.error = true;
        this.errorInfo = errorInfo.info;
        this.errorArgs = errorInfo.args;
    }

    static assertIsArithmeticOperand( tokens ) {
        return tokens.every( token => token.belongsTo( LOGICAL_TOKEN_TYPE.ARITHMETIC_OPERAND ) );
    }

    static assertIsNumeric( tokens ) {
        return tokens.every( token =>
            token.getOperandType() == TOKEN_TYPE.NUMBER ||
            token.getOperandType() == TOKEN_TYPE.DECIMAL
        );
    }

    static assertIsVariable( token ) {
        return token.getType() == TOKEN_TYPE.VARIABLE;
    }
}