import { additionOperation } from "./Implementation/CommonOperations.js"
import { substractionOperation } from "./Implementation/CommonOperations.js"
import { multiplicationOperation } from "./Implementation/CommonOperations.js"
import { divisionOperation } from "./Implementation/CommonOperations.js"
import { negateOperation } from "./Implementation/CommonOperations.js"
import { assignOperation } from "./Implementation/CommonOperations.js"
import { predecrementOperation } from "./Implementation/CommonOperations.js"
import { postdecrementOperation } from "./Implementation/CommonOperations.js"

export const Operations = {
    ADDITION_OP: additionOperation,
    SUBSTRACTION_OP: substractionOperation,
    MULTIPLICATION_OP: multiplicationOperation,
    DIVISION_OP: divisionOperation,
    NEGATE_OP: negateOperation,
    ASSIGN_OP: assignOperation,
    PREDECREMENT_OP: predecrementOperation,
    POSTDECREMENT_OP: postdecrementOperation,
}