import Config from "../Config.js";
import { MathematicalFunctions } from "./MathematicalFunctions/MathematicalFunctionsCatalog.js";

export default class FunctionsManager
{
    static create() {
        FunctionsManager.self = new FunctionsManager();
        FunctionsManager.self.functions = {};
        FunctionsManager.self.functionGroups = {};
        FunctionsManager.self.addFunctionGroups();
        FunctionsManager.self.setupFunctions( Config.getFunctions() );
    }

    static self = null;

    static getFunctions() {
        return FunctionsManager.self.functions;
    }

    addFunctionGroups() {
        this.functionGroups[ "MathematicalFunctions" ] = MathematicalFunctions;
    }

    setupFunctions( configFunctions ) {
        for ( let [ k, v ] of Object.entries( configFunctions ) ) {
            if ( v.keyword in this.functions ) {
                // error: repeated function keyword for key k!
            } else {
                this.functions[ v.keyword ] = {
                    keyword: v.keyword,
                    group: v.group,
                    name: v.name,
                    description: v.description,
                    precedence: v.precedence,
                    arguments: v.arguments,
                    function: this.findFunction( v.group, v.name )
                };
            }
        }
    }

    findFunction( group, name ) {
        const functionGroup = this.functionGroups[ group ];
        if ( functionGroup ) {
            return functionGroup[ name ];
        } else {
            // error: no function group with name 'group' has been found!
        }
    }

}