import { TOKEN_TYPE } from "../TokenType.js";
import Token from "../Token.js";

export default class OperatorToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.OPERATOR, value );
    }

    getOperatorType() {
        return this.value.type;
    }

    getPrecedence() {
        return this.value.precedence;
    }

    toString() {
        return this.value.operator;
    }
}