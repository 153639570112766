export const Settings = {
    vars: {
        "global_prefix": "global",
    },
    chars: {
        "DECIMAL_SEPARATOR": ".",
        "LEFT_PARENTHESIS": "(",
        "RIGHT_PARENTHESIS": ")",
        "COMMA": ",",
        "NAMESPACE_SEPARATOR": ".",
        "STRING_DELIMITER": '"',
        "START_VECTOR_DELIMITER": "[",
        "END_VECTOR_DELIMITER": "]",
    }
};
