import { Settings } from "./../config/settings.js";
import { Operators } from "./../config/operators.js";
import { Operations } from "./../config/operations.js";
import { Functions } from "./../config/functions.js";
import { Constants } from "../config/constants.js";
import ExpressionParser from "./modules/ExpressionParser.js";

let parser = null;

const init = function()
{
    parser = new ExpressionParser({
        settings: Settings,
        operators: Operators,
        operations: Operations,
        functions: Functions,
        constants: Constants,
    });
    //
    enableCalculateButton( true );
}

const onCalculate = function() {
    const expression = document.getElementById( "expression" ).value;
    parser.parse( expression );
    const output = parser.getShuntingYardOutput();
    const results = parser.getResults();
    clearTable();
    drawTable( output );
    drawResult( results );
    drawError( parser.getError() );
}

const enableCalculateButton = function( enabled ) {
    if ( enabled ) {
        document.getElementById( "calculate" ).removeAttribute( "disabled" );

    } else {
        document.getElementById( "calculate" ).setAttribute( "disabled", "" );
    }

}

const clearTable = function() {
    const tableBody = document.getElementById( "tablebody" );
    tableBody.textContent = '';
}

const drawError = function( parserError ) {
    const errorElement = document.getElementById( "error-row" );
    if ( parserError != null ) {
        if ( parserError != null ) {
            const tdErrorCode = document.getElementById( "error-code" );
            const errorCode = document.createTextNode( parserError.errorInfo.Code );
            tdErrorCode.textContent = "";
            tdErrorCode.appendChild( errorCode );
            const tdErrorDescription = document.getElementById( "error-description" );
            const errorDescription = document.createTextNode( parserError.errorInfo.Description );
            tdErrorDescription.textContent = "";
            tdErrorDescription.appendChild( errorDescription );
            const tdErrorArgs = document.getElementById( "error-arguments" );
            const errorArgs = document.createTextNode( parserError.errorArgs != null ? parserError.errorArgs : "" );
            tdErrorArgs.textContent = "";
            tdErrorArgs.appendChild( errorArgs );
            const element = document.getElementById( "resultop" );
            element.textContent = "#ERROR#";
            //
            errorElement.classList.remove( "d-none" );
        } else {
            errorElement.classList.add( "d-none", "" );
        }
    } else {
        errorElement.classList.add( "d-none", "" );
    }
}

const drawResult = function( results ) {
    const element = document.getElementById( "resultop" );
    let result = "";
    for ( let i = 0; i < results.length; ++i ) {
        if ( i != 0 ) {
            result += ", ";
        }
        result += results[ i ];
    }
    //
    element.textContent = result;
}

const drawTable = function( output ) {
    const tableBody = document.getElementById( "tablebody" );
    output.forEach( element => {
        const tr = document.createElement( "tr" );
        const tdtoken = document.createElement( "td" );
        const token = document.createTextNode( element.getType() );
        const tdtype = document.createElement( "td" );
        const type = document.createTextNode( element.getOperatorType() );
        const tdvalue = document.createElement( "td" );
        const value = document.createTextNode( element.toString() );
        tdtoken.appendChild( token );
        tdtype.appendChild( type );
        tdvalue.appendChild( value );
        tr.appendChild( tdtoken );
        tr.appendChild( tdtype );
        tr.appendChild( tdvalue );
        tableBody.appendChild( tr );
    });
}

const onStart = function() {
    enableCalculateButton( false );
    document.getElementById( "calculate" ).addEventListener( "click", onCalculate );
    //
    init();
};


document.addEventListener( "DOMContentLoaded", onStart );