import ErrorManager from "../ErrorManager.js";
import { TOKEN_TYPE } from "./../Tokenizer/TokenType.js";

export default class ShuntingYard {

    static getOutput( tokens ) {
        const outputQueue = [];
        const operatorStack = [];
        let functionMarkerCounter = 0;
        let parenthesisCounter = 0;
        //tokens.forEach( token => {
        for ( let i = 0; i < tokens.length; ++i ) {
            const token = tokens[ i ];
            switch ( token.getType() ) {
                case TOKEN_TYPE.DECIMAL:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.NUMBER:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.CONSTANT:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.VARIABLE:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.STRING:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.LEFT_VECTOR:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.RIGHT_VECTOR:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.LEFT_TUPLE:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.RIGHT_TUPLE:
                    outputQueue.push( token );
                    break;
                case TOKEN_TYPE.OPERATOR:
                    while ( operatorStack.length > 0 && ShuntingYard.peek( operatorStack ).getPrecedence() > token.getPrecedence() )
                    {
                        outputQueue.push( operatorStack.pop() );
                    }
                    operatorStack.push( token );
                    break;
                case TOKEN_TYPE.FUNCTION:
                    while ( operatorStack.length > 0 && ShuntingYard.peek( operatorStack ).getPrecedence() > token.getPrecedence() )
                    {
                        outputQueue.push( operatorStack.pop() );
                    }
                    operatorStack.push( token );
                    break;
                case TOKEN_TYPE.LEFT_PARENTHESIS:
                    parenthesisCounter++;
                    if ( operatorStack.length > 0 && ShuntingYard.peek( operatorStack ).getType() == TOKEN_TYPE.FUNCTION ) {
                        functionMarkerCounter++;
                        outputQueue.push( token );
                        operatorStack.push( token );
                    } else {
                        if ( functionMarkerCounter > 0 ) {
                            functionMarkerCounter++;
                        }
                        operatorStack.push( token );
                    }
                    break;
                case TOKEN_TYPE.RIGHT_PARENTHESIS:
                    parenthesisCounter--;
                    while( operatorStack.length > 0 && ShuntingYard.peek( operatorStack ).getType() != TOKEN_TYPE.LEFT_PARENTHESIS )
                    {
                        outputQueue.push( operatorStack.pop() );
                    }
                    operatorStack.pop();
                    if ( functionMarkerCounter > 0 ) {
                        functionMarkerCounter--;
                    }
                    if ( parenthesisCounter < 0 ) {
                        return {
                            output: [],
                            error: true,
                            errorInfo: ErrorManager.Error.MISMATCH_PARENTHESIS,
                            errorArgs: null
                        };
                    }
                    break;
                case TOKEN_TYPE.COMMA:
                    while ( operatorStack.length > 0 && ShuntingYard.peek( operatorStack ).getType() != TOKEN_TYPE.LEFT_PARENTHESIS )
                    {
                        outputQueue.push( operatorStack.pop() );
                    }
                    outputQueue.push( token );
                    break;
            }
        }
        if ( parenthesisCounter != 0 ) {
            return {
                output: [],
                error: true,
                errorInfo: ErrorManager.Error.MISMATCH_PARENTHESIS,
                errorArgs: null
            };
        }
        while ( operatorStack.length > 0 ) {
            outputQueue.push( operatorStack.pop() );
        }
        //
        return {
            output: outputQueue,
            error: false
        };
    }

    static peek( operatorStack ) {
        return operatorStack[ operatorStack.length - 1 ];
    }

}