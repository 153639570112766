import { TOKEN_TYPE } from "../../Tokenizer/TokenType.js";
import Token from "../../Tokenizer/Token.js";
import ErrorManager from "../../ErrorManager.js";
import TokenFactory from "../../Tokenizer/TokenFactory.js";

export const additionOperation = function ( args ) {
    if ( !ErrorManager.assertIsArithmeticOperand( args ) ) {
        return TokenFactory.createErrorToken( ErrorManager.Error.OPERATOR_ARGUMENTS_TYPE_NOT_VALID );
    }
    if ( args[ 0 ].getOperandType() == TOKEN_TYPE.DECIMAL || args[ 1 ].getOperandType() == TOKEN_TYPE.DECIMAL ) {
        return TokenFactory.createDecimalToken( args[ 1 ].getValue() + args[ 0 ].getValue() );
    } else {
        return TokenFactory.createNumberToken( args[ 1 ].getValue() + args[ 0 ].getValue() );
    }
}

export const substractionOperation = function ( args ) {
    if ( !ErrorManager.assertIsArithmeticOperand( args ) ) {
        return TokenFactory.createErrorToken( ErrorManager.Error.OPERATOR_ARGUMENTS_TYPE_NOT_VALID );
    }
    if ( args[ 0 ].getOperandType() == TOKEN_TYPE.DECIMAL || args[ 1 ].getOperandType() == TOKEN_TYPE.DECIMAL ) {
        return TokenFactory.createDecimalToken( args[ 1 ].getValue() - args[ 0 ].getValue() );
    } else {
        return TokenFactory.createNumberToken( args[ 1 ].getValue() - args[ 0 ].getValue() );
    }
}

export const multiplicationOperation = function ( args ) {
    if ( !ErrorManager.assertIsArithmeticOperand( args ) ) {
        return TokenFactory.createErrorToken( ErrorManager.Error.OPERATOR_ARGUMENTS_TYPE_NOT_VALID );
    }
    if ( args[ 0 ].getOperandType() == TOKEN_TYPE.DECIMAL || args[ 1 ].getOperandType() == TOKEN_TYPE.DECIMAL ) {
        return TokenFactory.createDecimalToken( args[ 1 ].getValue() * args[ 0 ].getValue() );
    } else {
        return TokenFactory.createNumberToken( args[ 1 ].getValue() * args[ 0 ].getValue() );
    }
}

export const divisionOperation = function ( args ) {
    if ( !ErrorManager.assertIsArithmeticOperand( args ) ) {
        return TokenFactory.createErrorToken( ErrorManager.Error.OPERATOR_ARGUMENTS_TYPE_NOT_VALID );
    }
    return TokenFactory.createDecimalToken( args[ 1 ].getValue() / args[ 0 ].getValue() );
}

export const negateOperation = function( args ) {
    if ( !ErrorManager.assertIsArithmeticOperand( args ) ) {
        return TokenFactory.createErrorToken( ErrorManager.Error.OPERATOR_ARGUMENTS_TYPE_NOT_VALID );
    }
    return TokenFactory.createToken( args[ 0 ].getOperandType(), -args[ 0 ] );
}

export const assignOperation = function( args ) {
    if ( !ErrorManager.assertIsVariable( args[ 1 ] ) ) {
        return TokenFactory.createErrorToken( ErrorManager.Error.ASSIGMENT_OPERATOR_NEEDS_VARIABLE );
    }
    args[ 1 ].assign( args[ 0 ] );
    return args[ 1 ].getToken();
}

export const predecrementOperation = function( args ) {
    args[ 0 ].assignValue( args[ 0 ].getValue() - 1 );
    return args[ 0 ];
}

export const postdecrementOperation = function( args ) {
    args[ 0 ].addPostAction( function () { args[ 0 ].assignValue( args[ 0 ].getValue() - 1 ); } );
    return args[ 0 ];
}