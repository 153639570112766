import Config from "../Config.js";
import ErrorManager from "../ErrorManager.js";
import FunctionsManager from "../Functions/FunctionsManager.js";
import ConstantsManager from "../Variables/ConstantsManager.js";
import TokenFactory from "./TokenFactory.js";
import { TOKEN_TYPE } from "./TokenType.js";

export default class Tokenizer
{
    tokenize( expression ) {
        const trimmedExpression = expression.trim();
        let result = {
            expression: trimmedExpression,
            tokens: [],
            error: false,
            errorCode: -1,
            errorArgs: null
        };
        while ( result.expression.length > 0 && !result.error ) {
            this.doTokenize( result );
            if ( !result.error ) {
                if ( result.tokens[ result.tokens.length - 1 ].getType() == TOKEN_TYPE.VECTOR ) {
                    this.tokenizeVector( result.tokens[ result.tokens.length - 1 ] );
                }
            }
        }
        //
        return {
            tokens: result.tokens,
            error: result.error,
            errorInfo: result.errorInfo,
            errorArgs: result.errorArgs
        }
    }

    doTokenize( result ) {
        if ( !this.isComma( result ) ) {
            if ( !this.isParenthesis( result ) ) {
                if ( !this.isOperator( result ) ) {
                    if ( !this.isFunction( result ) ) {
                        if ( !this.isVar( result ) ) {
                            if ( !this.isValue( result ) ) {
                                if ( !this.isString( result ) ) {
                                    if ( !this.isVector( result ) ) {
                                        // error token not found:
                                        result.error = true;
                                        result.errorInfo = ErrorManager.Error.TOKEN_NOT_FOUND;
                                        result.errorArgs = result.expression.charAt( 0 );
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    tokenizeVector( vectorToken ) {

    }

    isComma( result ) {
        if ( result.expression.length > 0 ) {
            if ( result.expression.charAt( 0 ) == TokenFactory.getComma() ) {
                result.tokens.push( TokenFactory.createCommaToken() );
                result.expression = result.expression.substring( 1 ).trim();
                return true;
            }
        }
        return false;
    }

    isOperator( result ) {
        for ( let i = 0; i < Config.getOperators().length; ++i ) {
            const operator = Config.getOperators()[ i ];
            let ok = true;
            for ( let i = 0; i < operator.operator.length; ++i ) {
                if ( operator.operator.charAt( i ) != result.expression.charAt( i ) ) {
                    ok = false;
                    break;
                }
            }
            if ( ok ) {
                const lastToken = this.getLastToken( result );
                let isUnitaryCondition = true;
                if ( lastToken != null ) {
                    isUnitaryCondition = TokenFactory.isOperatorTokenType( lastToken ) ||
                                         TokenFactory.isLeftParenthesisTokenType( lastToken ) ||
                                         TokenFactory.isCommaTokenType( lastToken );
                }
                if ( operator.type == "postfix" ) {
                    if ( TokenFactory.isVariableTokenType( this.getLastToken( result ) ) ) {
                        result.expression = result.expression.substring( operator.operator.length ).trim();
                        result.tokens.push( TokenFactory.createOperatorToken( operator ) );
                        return true;
                    }
                } else if ( operator.type == "prefix" ) {
                    const oldExpression = result.expression;
                    let isOk = true;
                    result.expression = result.expression.substring( operator.operator.length ).trim();
                    result.tokens.push( TokenFactory.createOperatorToken( operator ) );
                    if ( this.isVar( result ) ) {
                        if ( !TokenFactory.isVariableTokenType( this.getLastToken( result ) ) ) {
                            // Error, argument must be a var!
                            result.tokens.pop();
                            result.tokens.pop();
                            isOk = false;
                        }
                    } else {
                        result.tokens.pop();
                        isOk = false;
                    }
                    if ( !isOk ) {
                        result.expression = oldExpression;
                    }
                    if ( isOk ) {
                        return true;
                    }
                } else if ( operator.type == "unitary" ) {
                    if ( isUnitaryCondition ) {
                    result.expression = result.expression.substring( operator.operator.length ).trim();
                    result.tokens.push( TokenFactory.createOperatorToken( operator ) );
                    return true;
                    }
                } else if ( operator.type == "normal" ) {
                    if ( !isUnitaryCondition ) {
                    result.expression = result.expression.substring( operator.operator.length ).trim();
                    result.tokens.push( TokenFactory.createOperatorToken( operator ) );
                    return true;
                    }
                }
            }
        };
        return false;
    }

    isFunction( result ) {
        for ( let [ k, v ] of Object.entries( FunctionsManager.getFunctions() ) ) {
            let ok = true;
            for ( let i = 0; i < k.length; ++i ) {
                if ( k.charAt( i ) != result.expression.charAt( i ) ) {
                    ok = false;
                    break;
                }
            }
            if ( ok ) {
                result.expression = result.expression.substring( k.length ).trim();
                result.tokens.push( TokenFactory.createFunctionToken( v) );
                return true;
            }
        }
        return false;
    }

    isParenthesis( result ) {
        if ( result.expression.length > 0 ) {
            if ( result.expression.charAt( 0 ) == TokenFactory.getLeftParenthesis() ) {
                result.tokens.push( TokenFactory.createLeftParenthesisToken() );
                result.expression = result.expression.substring( 1 ).trim();
                return true;
            }
            if ( result.expression.charAt( 0 ) == TokenFactory.getRightParenthesis() ) {
                result.tokens.push( TokenFactory.createRightParenthesisToken() );
                result.expression = result.expression.substring( 1 ).trim();
                return true;
            }
        }
        return false;
    }

    isVar( result ) {
        let pos = 0;
        let first = true;
        while ( result.expression.length > pos && this.isVarName( result.expression.charAt( pos ), first ) ) {
            pos++;
            first = false;
        }
        if ( pos > 0 ) {
            const name = result.expression.substring( 0, pos );
            if ( this.isValidVariableName( name ) ) {
                if ( ConstantsManager.isConstant( name ) ) {
                    result.tokens.push( ConstantsManager.getConstantToken( name ) );
                    result.expression = result.expression.substring( pos ).trim();
                    return true;
                } else {
                    result.tokens.push( TokenFactory.createVariableToken( name ) );
                    result.expression = result.expression.substring( pos ).trim();
                    return true;
                }
            }
        }
        //
        return false;
    }

    isValue( result ) {
        let decimal = false;
        let pos = 0;
        while ( result.expression.length > pos && this.isNumeric( result.expression.charAt( pos ), decimal ) ) {
            if ( result.expression.charAt( pos ) == Config.getDecimalSeparator() ) {
                decimal = true;
            }
            pos++;
        }
        if ( decimal && pos > 1 ) {
            result.tokens.push( TokenFactory.createDecimalToken( parseFloat( result.expression.substring( 0, pos ) ) ) );
            result.expression = result.expression.substring( pos ).trim();
            return true;
        } else if ( !decimal && pos > 0 ) {
            result.tokens.push( TokenFactory.createNumberToken( parseInt( result.expression.substring( 0, pos ) ) ) );
            result.expression = result.expression.substring( pos ).trim();
            return true;
        }
        return false;
    }

    isString( result ) {
        if ( result.expression.charAt( 0 ) == Config.getStringDelimiter() ) {
            let pos = 1;
            while ( result.expression.length > pos && result.expression.charAt( pos ) != Config.getStringDelimiter() ) {
                pos++;
            }
            if ( result.expression.length <= pos ) {
                // Error no end delimiter found
                result.tokens = [];
                result.error = true;
                result.errorInfo = ErrorManager.Error.NO_END_DELIMITER_FOR_STRING;
                result.errorArgs = "";
                return true;
            }
            pos++;
            result.tokens.push( TokenFactory.createStringToken( result.expression.substring( 0, pos ) ) );
            result.expression = result.expression.substring( pos ).trim();
            return true;
        }
        return false;
    }

    isVector( result ) {
        if ( result.expression.length > 1 &&
             result.expression.charAt( 0 ) == Config.getStartVectorDelimiter() &&
             result.expression.charAt( 1 ) == Config.getStartVectorDelimiter() ) {
            result.tokens.push( TokenFactory.createLeftTupleToken() );
            result.expression = result.expression.substring( 2 ).trim();
            return true;
        }
        if ( result.expression.length > 1 &&
             result.expression.charAt( 0 ) == Config.getEndVectorDelimiter() &&
             result.expression.charAt( 1 ) == Config.getEndVectorDelimiter() ) {
            result.tokens.push( TokenFactory.createRightTupleToken() );
            result.expression = result.expression.substring( 2 ).trim();
            return true;
        }
        if ( result.expression.charAt( 0 ) == Config.getStartVectorDelimiter() ) {
            result.tokens.push( TokenFactory.createLeftVectorToken() );
            result.expression = result.expression.substring( 1 ).trim();
            return true;
        }
        if ( result.expression.charAt( 0 ) == Config.getEndVectorDelimiter() ) {
            result.tokens.push( TokenFactory.createRightVectorToken() );
            result.expression = result.expression.substring( 1 ).trim();
            return true;
        }
        return false;
    }

    isNumeric( c, decimal ) {
        if ( c >= '0' && c <= '9' ) {
            return true;
        }
        if ( c == Config.getDecimalSeparator() ) {
            return !decimal;
        }
    }

    isVarName( c, first ) {
        if ( c >= 'a' && c <= 'z' ) {
            return true;
        } else if ( c >= 'A' && c <= 'Z' ) {
            return true;
        } else if ( c == '_' ) {
            return true;
        } else if ( c >= '0' && c <= '9' ) {
            return !first;
        } else if ( c == Config.getNamespaceSeparator() )
        {
            return true;
        }
        //
        return false;
    }

    isValidVariableName( name ) {
        name.trim();
        if ( name.length > 0 ) {
            if ( !this.isNumeric( name[ 0 ], false ) ) {
                if ( name[ name.length-1 ] != Config.getNamespaceSeparator() ) {
                    if ( !name.includes( Config.getNamespaceSeparator() + Config.getNamespaceSeparator() ) ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    getLastToken( result ) {
        if ( result.tokens.length > 0 ) {
            return result.tokens[ result.tokens.length - 1 ];
        }
        return null;
    }

}