import { TOKEN_TYPE } from "../TokenType.js";
import Token from "../Token.js";
import VariablesManager from "../../Variables/VariablesManager.js";
import TokenFactory from "../TokenFactory.js";

export default class VariableToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.VARIABLE, value );
    }

    assign( token ) {
        this.token = ( token.getType() == TOKEN_TYPE.VARIABLE ) ? token.token.clone() : token.clone();
        VariablesManager.assign( this.value, this.token );
    }

    assignValue( value ) {
        this.token = TokenFactory.createTokenFromValue( value );
        VariablesManager.assign( this.value, this.token );
    }

    getOperandType() {
        if ( this.token != null ) {
            return this.token.getType();
        }
        return TOKEN_TYPE.UNDEFINED;
    }

    getValue() {
        if ( this.token != null ) {
            return this.token.getValue();
        }
        return null;
    }

    addPostAction( action ) {
        this.postActions.push( action );
    }

    executePostActions() {
        while ( this.postActions.length > 0 ) {
            this.postActions.pop()();
        }
    }

    getName() {
        return this.value;
    }

    getOperatorType() {
        return this.value.toString();
    }

    toString() {
        return this.token.getValue();
    }

}
