export const TOKEN_TYPE = {
    UNDEFINED: "undefined",
    ERROR: "error",
    OPERATOR: "operator",
    NUMBER: "number",
    DECIMAL: "decimal",
    STRING: "string",
    BOOLEAN: "boolean",
    LEFT_PARENTHESIS: "left parenthesis",
    RIGHT_PARENTHESIS: "right parenthesis",
    FUNCTION: "function",
    COMMA: "comma",
    VARIABLE: "variable",
    CONSTANT: "constant",
    VECTOR: "vector",
    LEFT_VECTOR: "left vector delimiter",
    RIGHT_VECTOR: "right vector delimiter",
    TUPLE: "tuple",
    LEFT_TUPLE: "left tuple delimiter",
    RIGHT_TUPLE: "right tuple delimiter"
};
