import Config from "./Config.js";
import ShuntingYard from "./ShuntingYard/ShuntingYard.js";
import Tokenizer from "./Tokenizer/Tokenizer.js";
import Solver from "./Solver/Solver.js";
import VariablesManager from "./Variables/VariablesManager.js";
import ConstantsManager from "./Variables/ConstantsManager.js";
import FunctionsManager from "./Functions/FunctionsManager.js";
import ErrorManager from "./ErrorManager.js";

export default class ExpressionParser
{
    constructor( configuration ) {
        this.shuntingYardOutput = [];
        this.init( configuration )
    }

    init( configuration ) {
        Config.create({
            settings: configuration.settings,
            operators: configuration.operators.sort( function( operator1, operator2 ) {
                if ( operator1.operator.length > operator2.operator.length ) {
                    return -1;
                } else if ( operator1.operator.length < operator2.operator.length ) {
                    return 1;
                }
                return 0;
            }),
            operations: configuration.operations,
            functions: configuration.functions,
            constants: configuration.constants,
        });
        //
        ConstantsManager.create();
        //
        VariablesManager.create();
        //
        FunctionsManager.create();
        //
        this.errorManager = new ErrorManager();
        //
        this.tokenizer = new Tokenizer();
        //
        this.solver = new Solver();
    }

    parse( expression ) {
        this.result = null;
        this.errorManager.reset();
        const tokenizerResult = this.tokenize( expression );
        if ( !tokenizerResult.error ) {
            const shuntingYardOutputResult = ShuntingYard.getOutput( tokenizerResult.tokens );
            if ( !shuntingYardOutputResult.error ) {
                this.shuntingYardOutput = shuntingYardOutputResult.output;
                const solverResult = this.solver.solve( this.shuntingYardOutput );
                if ( solverResult.error ) {
                    this.result = {
                        result: [],
                        error: true
                    };
                    this.errorManager.setError({
                        info: solverResult.errorInfo,
                        args: solverResult.errorArgs
                    });
                } else {
                    this.result = {
                        result: solverResult.result,
                        error: false
                    };
                }
            } else {
                this.result = {
                    error: true,
                    result: []
                };
                this.errorManager.setError({
                    info: shuntingYardOutputResult.errorInfo,
                    args: shuntingYardOutputResult.errorArgs
                });
            }
        }
        else {
            this.result = {
                error: true,
                result: []
            };
            this.errorManager.setError({
                info: tokenizerResult.errorInfo,
                args: tokenizerResult.errorArgs
            });
        }
    }

    tokenize( expression ) {
        return this.tokenizer.tokenize( expression );
    }

    getShuntingYardOutput() {
        return this.shuntingYardOutput;
    }

    getResult() {
        return this.result.error ? "" : this.result.result[ this.result.result.length - 1 ].getValue();
    }

    getResults() {
        const results = this.result.result.map( token => {
            return token.getValue();
        });
        return results;
    }

    getError() {
        if ( !this.errorManager.isError() ) {
            return null;
        }
        return {
            errorInfo: this.errorManager.getErrorInfo(),
            errorArgs: this.errorManager.getErrorArgs()
        };
    }
}