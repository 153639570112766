import { TOKEN_TYPE } from "../TokenType.js";
import Token from "../Token.js";

export default class FunctionToken extends Token {
    constructor( value ) {
        super( TOKEN_TYPE.FUNCTION, value );
    }

    toString() {
        return this.value.keyword;
    }

    getPrecedence() {
        return this.value.precedence;
    }
}
