import { TOKEN_TYPE } from "./../../../Tokenizer/TokenType.js";
import Token from "./../../../Tokenizer/Token.js";

export const sinFunction = function( args ) {
    if ( args.length == 1 ) {
        if ( args[ 0 ].getType() == TOKEN_TYPE.NUMBER || args[ 0 ].getType() == TOKEN_TYPE.DECIMAL ) {
            return new Token( TOKEN_TYPE.DECIMAL, Math.sin( args[ 0 ].getValue() ) );
        }
    } else {
        // error: incorrect number of arguments
    }
}