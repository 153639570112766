import { TOKEN_TYPE } from "./TokenType.js";
import Config from "../Config.js";
import UndefinedToken from "./Tokens/UndefinedToken.js";
import OperatorToken from "./Tokens/OperatorToken.js";
import FunctionToken from "./Tokens/FunctionToken.js";
import LeftParenthesisToken from "./Tokens/LeftParenthesisToken.js";
import RightParenthesisToken from "./Tokens/RightParenthesisToken.js";
import ConstantToken from "./Tokens/ConstantToken.js";
import StringToken from "./Tokens/StringToken.js";
import BooleanToken from "./Tokens/BooleanToken.js";
import NumberToken from "./Tokens/NumberToken.js";
import DecimalToken from "./Tokens/NumberToken.js";
import VariableToken from "./Tokens/VariableToken.js";
import CommaToken from "./Tokens/CommaToken.js";
import ErrorToken from "./Tokens/ErrorToken.js";
import VectorToken from "./Tokens/VectorToken.js";
import LeftVectorToken from "./Tokens/LeftVectorToken.js";
import RightVectorToken from "./Tokens/RightVectorToken.js";
import LeftTupleToken from "./Tokens/LeftTupleToken.js";
import RightTupleToken from "./Tokens/RightTupleToken.js";
import TupleToken from "./Tokens/TupleToken.js";

export default class TokenFactory {

    static createToken( tokenType, value ) {
        if ( tokenType == TOKEN_TYPE.DECIMAL ) {
            return this.createDecimalToken( value );
        } else if ( tokenType == TOKEN_TYPE.NUMBER ) {
            return this.createNumberToken( value );
        } else if ( tokenType == TOKEN_TYPE.BOOLEAN ) {
            return this.createBooleanToken( value );
        } else if ( tokenType == TOKEN_TYPE.STRING ) {
            return this.createStringToken( value );
        } else {
            return this.createUndefinedToken();
        }
    }

    static createUndefinedToken() {
        return new UndefinedToken();
    }

    static createOperatorToken( value ) {
        return new OperatorToken( value );
    }

    static createCommaToken() {
        return new CommaToken( Config.getComma() );
    }

    static createFunctionToken( value ) {
        return new FunctionToken( value );
    }

    static createLeftParenthesisToken() {
        return new LeftParenthesisToken( Config.getLeftParenthesis() );
    }

    static createRightParenthesisToken() {
        return new RightParenthesisToken( Config.getRightParenthesis() );
    }

    static createConstantToken( value ) {
        return new ConstantToken( value );
    }

    static createStringToken( value ) {
        return new StringToken( value );
    }

    static createBooleanToken( value ) {
        return new BooleanToken( value );
    }

    static createNumberToken( value ) {
        return new NumberToken( value );
    }

    static createDecimalToken( value ) {
        return new DecimalToken( value );
    }

    static createVariableToken( value ) {
        return new VariableToken( value );
    }

    static createErrorToken( errorInfo ) {
        return new ErrorToken( errorInfo );
    }

    static createVectorToken() {
        return new VectorToken( "" );
    }

    static createLeftVectorToken() {
        return new LeftVectorToken( Config.getStartVectorDelimiter() );
    }

    static createRightVectorToken() {
        return new RightVectorToken( Config.getEndVectorDelimiter() );
    }

    static createLeftTupleToken() {
        return new LeftTupleToken( Config.getStartVectorDelimiter() + Config.getStartVectorDelimiter() );
    }

    static createRightTupleToken() {
        return new RightTupleToken( Config.getEndVectorDelimiter() + Config.getEndVectorDelimiter() );
    }

    static createTupleToken() {
        return new TupleToken( "" );
    }

    static createTokenFromConstant( constantName, constantValue ) {
        const constantToken = TokenFactory.createConstantToken( constantName );
        constantToken.token = TokenFactory.createTokenFromValue( constantValue );
        return constantToken;
    }

    static createTokenFromValue( value ) {
        const valueType = typeof( value );
        if ( valueType === "string" ) {
            return TokenFactory.createStringToken( value );
        } else if ( valueType === "boolean" ) {
            return TokenFactory.createBooleanToken( value );
        } else if ( valueType === "number" ) {
            if ( Number.isInteger( value ) ) {
                return TokenFactory.createNumberToken( value );
            } else {
                return TokenFactory.createDecimalToken( value );
            }
        }
        return TokenFactory.createUndefinedToken();
    }

    static getComma() {
        return Config.getComma();
    }

    static getLeftParenthesis() {
        return Config.getLeftParenthesis();
    }

    static getRightParenthesis() {
        return Config.getRightParenthesis();
    }

    static isOperatorTokenType( token ) {
        return token.getType() == TOKEN_TYPE.OPERATOR;
    }

    static isLeftParenthesisTokenType( token ) {
        return token.getType() == TOKEN_TYPE.LEFT_PARENTHESIS;
    }

    static isCommaTokenType( token ) {
        return token.getType() == TOKEN_TYPE.COMMA;
    }

    static isVariableTokenType( token ) {
        return token.getType() == TOKEN_TYPE.VARIABLE;
    }
}