import Config from "../Config.js";
import TokenFactory from "../Tokenizer/TokenFactory.js";

export default class ConstantsManager
{
    static create() {
        ConstantsManager.self = new ConstantsManager();
        ConstantsManager.self.constants = {};
        ConstantsManager.self.constantsTokens = {};
        ConstantsManager.self.setupConstants( Config.getConstants() );
    }

    static self = null;

    static isConstant( constantName ) {
        if ( !constantName.includes( Config.getNamespaceSeparator() ) ) {
            return Config.getGlobalPrefix() + Config.getNamespaceSeparator() + constantName in ConstantsManager.self.constants;
        }
        //
        return constantName in ConstantsManager.self.constants;
    }

    static getConstantToken( constantName ) {
        if ( !constantName.includes( Config.getNamespaceSeparator() ) ) {
            return ConstantsManager.self.constantsTokens[ Config.getGlobalPrefix() + Config.getNamespaceSeparator() + constantName ];
        }
        //
        return ConstantsManager.self.constantsTokens[ constantName ];
    }

    setupConstants( configConstants ) {
        for ( let [ k, v ] of Object.entries( configConstants ) ) {
            this.addToConstants( "", k, v );
        }
        //
        for ( let [ constantName, constantValue ] of Object.entries( this.constants ) ) {
            this.constantsTokens[ constantName ] = TokenFactory.createTokenFromConstant( constantName, constantValue );
        }
    }

    addToConstants( name, key, value ) {
        if ( typeof value === 'object' ) {
            name = key;
            for ( let [ k, v ] of Object.entries( value ) ) {
                this.addToConstants( name, k, v );
            }
        } else {
            this.constants[ name + "." + key ] = value;
        }
    }
}