import TokenFactory from "../Tokenizer/TokenFactory.js";

export default class VariablesManager {

    static create() {
        VariablesManager.self = new VariablesManager();
        VariablesManager.self.variables = {};
    }

    static self = null;

    static assign( variableName, tokenValue ) {
        VariablesManager.self.variables[ variableName ] = tokenValue;
    }

    static updateVariable( variableToken ) {
        if ( !VariablesManager.self.createVariable( variableToken.getName() ) ) {
            variableToken.setToken( VariablesManager.self.getVariable( variableToken.getName() ) );
        }
    }

    createVariable( variableName ) {
        if ( !this.variables.hasOwnProperty( variableName ) ) {
            this.variables[ variableName ] = null;
            return true;
        }
        return false;
    }

    addVariable( variableName, variableValue ) {
        this.variables[ variableName ] = TokenFactory.createTokenFromValue( variableValue );
    }

    getVariable( variableName ) {
        return this.variables[ variableName ];
    }

    getAssignVariableFunc() {
        const variables = this.variables;
        return function( variableName, variableToken ) {
            variables[ variableName ] = variableToken;
        };
    }
}